/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { getBodyStyle, getColor, Theme } from 'styles/theme';
import { breakpoint } from 'styles/globals';

export const keyTakeaways = css<{ theme: Theme }>`
  .key-takeaways-widget {
    padding: 24px;
    background-color: ${getColor('surfacePrimary')};
    border-top: 4px solid ${getColor('borderAccentPrimary')};
      
    .key-takeaways-header {
      display: flex;
      align-items: center;
      gap: 16px;
      margin: 0 0 16px 0;
      font-family: ${getBodyStyle('graphikCond', { default: 'large', mdMin: 'xlarge' }, 'semiBold')};
      
      &::before {
        content: '';
        background: url('/icons/KeyTakeaways.svg') no-repeat center/contain;
        width: 24px;
        height: 24px;  
      
        @media (${breakpoint.mdMin}) {
          width: 30px;
          height: 30px;
        }
      }
    }
      
    .key-takeaways-content {
      font-family: ${getBodyStyle('graphikCompact', { default: 'default' })};
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;
      p {
        margin: 16px 0;
      }
    }
  }
`;
