import { useState } from 'react';
import SocialLinks from 'components/SocialLinks';
import useBreakpoint from 'hooks/useBreakpoint';
import { EventData } from 'services/Gtm/entities';
import * as S from './SocialShareDropdownStyles';

interface Props {
  title: string;
  isArticleRedesign?: boolean;
  trackingData?: EventData;
  className?: string;
}

const SocialShareDropdown = ({ className, title, isArticleRedesign = false, trackingData }: Props) => {
  const [showContent, setShowContent] = useState(false);
  const isMobile = useBreakpoint('mdMax');

  const onClickHandler = () => setShowContent((sC) => !sC);

  return (
    <S.Wrapper>
      <S.DropdownContainer className={`${isArticleRedesign ? 'article-redesign' : ''}`}>
        <div
          role='button'
          className={`share-icon module impression-element ${className}`}
          data-content-placement='social_share_CTA'
          tabIndex={0}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          aria-label='share-button'
        >
          {!isArticleRedesign ? <p className='title'>Share</p> : null}
        </div>
        <S.Dropdown className={isMobile && showContent ? 'show' : `${isArticleRedesign ? 'article-redesign' : ''}`}>
          <S.DropdownContent>
            <SocialLinks
              title={title}
              className='social-links-container'
              trackingData={trackingData}
            />
          </S.DropdownContent>
        </S.Dropdown>
      </S.DropdownContainer>
    </S.Wrapper>
  );
};

export default SocialShareDropdown;
