/* eslint-disable import/prefer-default-export */

import { css } from 'styled-components';
import { basicTableStyling, bulletListStyling } from 'components/ProductCard/ProductCardStyles';
import { getColor, Theme } from 'styles/theme';
import { bluePrimaryLink, primaryLink } from 'components/Globals/Base/Link/LinkStyles';
import { primaryBrandButtonLarge, tertiaryButtonLarge } from 'components/Globals/Base/Button/buttonStyles';
import { ctaCaption } from 'styles/paragraphs';
import { breakpoint, color, font } from 'styles/globals';

const ratesStyles = css<{ theme: Theme }>`
  .card-rates-title {
    font-family: ${font.graphikCond};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    margin-bottom: 10px;
  }
  .card-rates-list {
    li {
      display: flex;
      gap:8px;
      font-family: ${font.graphikCond};
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 10px;
      
      .circle {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: ${getColor('neutralWhite')};
        background: ${color.recommendsGreen};
        border-radius: 50%;
        min-width: 24px;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

const tooltipStyles = css<{ theme: Theme }>`
  div[role="tooltip"] {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index: 3;
    margin: -6px 0;
    background-image: url('/icons/svg/tooltip.svg');
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;

    &:hover, &:focus, &:focus-within {
      z-index: 4;
    }
    
    & > svg {
      display: none;
    }

    &:focus:before, &:hover:before {
      background: ${color.recommendsDarkGreen}1A;
      border-radius: 50%;
    }

    &:focus:before {
      background: ${color.recommendsDarkGreen}33;
    }

    &:focus:after, &:hover:after {
      position: absolute;
      z-index: 3;
      font-family : ${font.graphikCompact};
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      padding: 8px;
      width: 175px;
      background-color: ${getColor('neutralWhite')};
      box-shadow: 2px 2px 4px ${`${getColor('neutralBlack')}26`};
      color: ${getColor('neutralBlack')};
      content: attr(data-tooltip);

      right: 0;
      bottom: 0;
      transform: translate(0, -24px);
      border-bottom: 4px solid ${color.recommendsDarkGreen};
    }
  }
`;

const accordionPannels: {
  [key: string]: number;
} = {
  'four-panels': 4,
  'four-pannels': 4,
  'one-panel': 1,
  'one-pannel': 1,
  'three-panels': 3,
  'three-pannels': 3,
  'two-panels': 2,
  'two-pannels': 2,
};

export const productCardStyles = css<{ theme: Theme }>`
  .product-card-inline-wrapper {
    border-top: 3px solid ${color.recommendsGreen};
    box-shadow: 0px 4px 20px 0px ${getColor('neutralBlack')}26;
    padding: 0 16px 16px;
    margin: 24px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    ${bulletListStyling}
    ${basicTableStyling}
    ${tooltipStyles}

    .card-banner {
      grid-column: 1 / span 2;
      grid-row: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${color.recommendsDarkGreen};
      color: ${getColor('neutralWhite')};
      padding: 8px;
      font-size: 16px;
      font-family: ${font.graphikCond};
      font-weight: 600;
      line-height: 150%;
      margin: -3px -16px 0px;
    }
    .cta-caption {
      ${ctaCaption}
    }

    .card-area-1 {
      grid-column: 1 / span 2;
      grid-row: 3;

      .card-title {
        margin: 0px 0px 24px;
        font-family: ${font.graphikCond};
        font-size: 32px;
        line-height: 120%;
        font-weight: 600;
        letter-spacing: 0.5px;
        display: inline-block;

        a {
          ${primaryLink};
          font-family: ${font.graphikCond};
          font-size: 32px;
          line-height: 120%;
          font-weight: 600;
          letter-spacing: 0.5px;
          display: inline-block;
          font-weight:inherit;
          font-family:inherit;
        }
      }

      .card-title + .card-subtitle {
        margin-top: -16px;
      }

      .card-subtitle + .card-intro {
        margin-top: 16px;

        @media (${breakpoint.mdMin}) {
          margin-top: 24px;
        }
      }

      .card-subtitle {
        font-family: ${font.graphikCond};
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.16px;
        color: ${getColor('neutralBlack')};
        display: block;
      }

      .card-subtitle-ad {
        font-size: 20px;
      }

      .card-subtitle + .card-description {
        margin-top: 24px;
      }

      .card-intro {
        padding: 8px;
        background-color: ${color.recommendsLightGreen};
        color: ${getColor('neutralBlack')};
        font-family: ${font.graphikCond};
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
        
        &>*:nth-child(1) {
          margin: 0;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
      }

      .card-description {
        color: ${getColor('neutralBlack')};
        font-family: ${font.graphikCond};
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
      }
    }

    .card-area-2 {
      grid-column: 1 / span 1;
      grid-row: 2;
      display: flex;
      flex-direction: column;

      img {
        width: 100%;
        max-height: 200px;
        object-fit: contain;
      }

      .card-image-caption, a {
        ${bluePrimaryLink}
        font-family: ${font.graphikCond};
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .card-area-3 {
      display: flex;
      flex-direction: column;  
      justify-content: center;
      grid-column: 1 / span 2;
      grid-row: 4;
      a {
        &.button-desktop {
          ${primaryBrandButtonLarge}
          width: 100%;
          // special case for product cards - the buttons will have smaller paddings
          padding: 16px;
          svg {
            display: none;
          }
        }

        &.link-desktop {
          ${tertiaryButtonLarge}
          // special case for product cards - the links will have smaller spacing
          margin: 16px;
          font-size: 26px;
          &::before {
            inset: -16px;
            }
          &::after { 
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .card-area-4 {
      grid-column: 1 / span 2;
      grid-row: 5;

      ${ratesStyles}

      .card-accordion {
        display: flex;
        flex-direction: column;
        color: ${getColor('neutralSoftBlack')};
        font-family: ${font.graphikCond};
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.25px;
        overflow: hidden;
        transition: all 0.3s ease;

        & > input[type="radio"] {
          display: none;
        }

        & > label {
          display: flex;
          justify-content: space-between;
          padding: 16px 0 8px;
          font-family: ${font.graphikCond};
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          border-bottom: 1px solid ${getColor('borderSecondary')};
          text-transform: uppercase;
          margin-bottom: 16px;
          cursor: pointer;

          svg {
            display: none;
          }

          &::after {
            content: url('/icons/BreadcrumbsGreenSeparator.svg');
            width: 18px;
            height: 18px;
            display: inline-block;
            transform: scale(1.3);
            transition: transform 0.7s ease ;
            stroke: ${color.recommendsDarkGreen};
          }
        }

        & > input[type="radio"]:checked + label {
          color: ${color.recommendsDarkGreen};
          svg {
            transform: rotate(270deg) scale(1.3);
          }

          &::after {
            transform: rotate(180deg) scale(1.3);
            transition: transform 0.7s ease;
          }
        }

        & > .card-accordion-panel {
          display: none;
        }
        
        & > input[type="radio"]:checked + label + .card-accordion-panel {
          display: block;
        }
        
        & > a, & > .cta-caption {
          display: none;
        }
        & > input[type="radio"]:checked ~ .cta-caption {
          display: block;
        }  
      
        & > input[type="radio"]:checked ~ a {
          width: 100%;
          &.button-mobile {
            ${primaryBrandButtonLarge}
            margin-top: 16px;
            svg {
              display: none;
            }
          }

          &.link-mobile {
            ${tertiaryButtonLarge}
            padding: 16px;
            margin: 0;
          }
        }
      }
    }

    @media (${breakpoint.mdMin}) {
      padding: 0 24px 24px;
      grid-template-columns: 1fr 168px;
      gap: 24px;

      .card-banner {
        margin: -3px -24px 0px;
      }

      .card-area-1 {
        grid-column: 1 / span 1;
        grid-row: 2 / span 2;
      }

      .card-area-2 {
        grid-column: 2 / span 1;
        grid-row: 2;
      }

      .card-area-3 {
        grid-column: 2 / span 1;
        grid-row: 3;
        margin-top: auto;
      }

      .card-area-4 {
        grid-column: 1 / span 2;
        grid-row: 4;

        .card-accordion {
          display: grid;
          grid-column: auto;

          & > label {
            padding: 8px 24px;
            grid-row: 2 / auto;
            border-bottom: 1px solid ${getColor('borderSecondary')};
            svg {
              display: none;
            }

            &::after{
              display: none;
            }
          }

          &:after {
            content: '';
            grid-row: 2 / auto;
            border-bottom: 1px solid ${getColor('borderSecondary')};
            margin-bottom: 16px;
          }

          & > input[type="radio"]:checked + label {
            border-bottom: 1px solid ${color.recommendsDarkGreen};
          }

          & > .card-accordion-panel {
            grid-row: 3 / auto;
          }
          
          // handling different stylings for different number of pannels
          ${Object.keys(accordionPannels).map(
    (pannelClass) => css`
            &.${pannelClass} {
              grid-template-columns: repeat(${accordionPannels[pannelClass]}, auto) 1fr;
              .card-accordion-panel {
                grid-column: 1 / span ${accordionPannels[pannelClass] + 1};
              }
            }`,
  )}  
          
          & > a, & > input[type="radio"]:checked ~ a {
            &.button-mobile,
            &.link-mobile {
              display: none;          
            }          
          }
          & > p, & > input[type="radio"]:checked ~ p {
            &.cta-caption {
              display: none;
            }
          }  
        }
      }
    }
  }
`;
