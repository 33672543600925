import RawHtml from 'utils/miscUtils/rawHtml';
import { PageType } from 'interfaces/content/articles/Post';
import SubscriptionPleaContainer from './SubscriptionPleaStyles';

export interface SubscriptionPleaProps {
  enableSubscriptionPlea: boolean;
  pageType: PageType;
  subscriptionPleaTextLined: string;
}

const SubscriptionPlea = (props: SubscriptionPleaProps) => {
  const { enableSubscriptionPlea, pageType, subscriptionPleaTextLined } = props;

  if (!subscriptionPleaTextLined) return null;

  return (
    <SubscriptionPleaContainer
      className={`${pageType} module impression-element`}
      $hasContent={subscriptionPleaTextLined}
      $showSubscriptionPlea={enableSubscriptionPlea}
      data-cy='subscriptionPlea'
      data-content-placement='recirculation_module'
    >
      {RawHtml({ html: subscriptionPleaTextLined })}
    </SubscriptionPleaContainer>
  );
};

export default SubscriptionPlea;
