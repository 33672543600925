import styled, { css } from 'styled-components';
import { blockQuotes, longQuotes, pullQuotes } from 'styles/quotes';
import { headers } from 'styles/headers';
import { images } from 'styles/images';
import { breakpoint, font } from 'styles/globals';
import { codeBlocks, paragraphs } from 'styles/paragraphs';
import { tables } from 'styles/tables';
import { videos } from 'styles/videos';
import { ArticleContentProps, articleContentStyle } from 'styles/articleContent';
import { Microsites } from 'constants/constants';
import Heading from 'styles/typography/Heading';
import { lists } from 'styles/lists';
import { getBodyStyle, getColor, Theme } from 'styles/theme';
import articleContentAnchorStyle from 'styles/articleContentAnchorStyles';

export const ArticlePageWrapper = styled.div<{ $isRecommends: boolean }>`
  margin-top: 24px;

  .presented-by-banner {
    margin-bottom: 24px;
  }

  @media (${breakpoint.mdMin}) {
    margin-top: 32px;
    padding: 0;
    .presented-by-banner {
      margin-bottom: 32px;
    }
  }

  @media (${breakpoint.xlMin}) {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-template-columns: 1.55fr minmax(0, 824px) 300px 1fr;
    margin-top: 48px;
    column-gap: 32px;

    .presented-by-banner {
      margin-bottom: 48px;
    }

    .sidebar-content-preview{
      margin-left: 0;
    }

    & > * {
      grid-column: 2 / span 2;
    }
  }

  .iframe-wrapper {
    height: 100%;
  }

  .aetna-form-iframe {
    aspect-ratio: 1/1 !important; // use !important to override third party styling
  }

  .key-takeaways-widget {
    margin: 48px 0;
  }
`;

export const BreadcrumbsContainer = styled.div`
  margin-bottom: 24px;

  @media (${breakpoint.mdMin}) {
    margin-bottom: 32px;
  }

  @media (${breakpoint.xlMin}) {
    margin-bottom: 48px;
  }
`;

export const ArticleHeroWrapper = styled.div`
  & > .articleSeriesTop {
    margin-bottom: 24px;
  }

  & > .hero-eyebrow {
    margin-bottom: 8px;
  }
`;

export const ArticleHeroTitle = styled(Heading)`
  margin-bottom: 8px;

  &.has-author-byline {
    margin-bottom: 16px;
  }

  @media (${breakpoint.xlMin}) {
    margin-bottom: 16px;
  }
`;

export const ArticleHeroBylineWrapper = styled.div`
  font-family: ${font.graphikCond};
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 21px;
`;

export const ArticleHeroAuthorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1.2px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 4px;

  @media (${breakpoint.smMin}) {
    flex-direction: row;
  }

  &.recommends-author {
    gap: 16px;
    margin-bottom: 16px;
    flex-direction: column;

    @media (${breakpoint.mdMin}) {
      flex-direction: row;
    }
  }
`;

export const ArticleHeroDate = styled.div<{ theme: Theme }>`
  color: ${getColor('textSecondary')};
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1.17px;
  line-height: 21px;
  min-height: 21px;
`;

export const ArticleImageAndContentContainer = styled.div`
  @media (${breakpoint.xlMin}) {
    grid-column: 2 / span 1;
  }
`;

export const ArticleHeroImageContainer = styled.div<{ $isRecommends: boolean }>`
  font-family: ${font.graphikCond};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.58px;
  line-height: 21px;
  margin: ${({ $isRecommends }) => ($isRecommends ? '0px -24px 0px' : '0px -16px 0px')}; ;

  @media (${breakpoint.mdMin}) {
    margin-left: auto;
    margin-right: 0;
  }

  .article-video {
    bottom: 32px;
  }

  /* placeholder for image */

  span {
    width: 100% !important;
  }

  /* placeholder for caption */

  p {
    margin: 0px 16px;

    @media (${breakpoint.mdMin}) {
      margin: 0px;
    }
  }
`;

export const Caption = styled.div<{ theme: Theme }>`
  margin: 8px 16px 0;
  color: ${getColor('textPrimary')};

  @media (${breakpoint.mdMin}) {
    margin: 8px 16px 0;
  }

  @media (${breakpoint.xlMin}) {
    margin: 0;
  }
`;

export const Credit = styled.div<{ theme: Theme }>`
  color: ${getColor('textSecondary')};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 18px;
  margin-top: 4px;
  text-transform: uppercase;
  margin: 4px 16px 0;

  @media (${breakpoint.mdMin}) {
    margin: 0 16px;
  }

  @media (${breakpoint.xlMin}) {
    margin: 0;
  }
`;

export const ArticleDateSocialsContainer = styled.div<{ theme: Theme }>`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;  
  .social-links {
    @media (${breakpoint.mdMin}) {
      &::before {
        content: none;
      }
      ul {
        position: static;
          display: flex;
          flex-direction: row;
          padding: 8px;

          & > li:not(:first-of-type) {
            margin: 0px 0px 0px 8px;
          }
      }
    }
  }
  @media (${breakpoint.mdMin}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ArticleAuthorDateSocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 24px;
  margin-bottom: 16px;

  @media (${breakpoint.mdMin}) {
    margin-bottom: 24px;
  }
`;

export const ArticleContentWrapper = styled.div<ArticleContentProps & { theme: Theme }>`
  ${getBodyStyle('georgia', { default: 'medium' })};
  margin-top: 24px;

  ${paragraphs};
  ${headers};
  ${images};
  ${blockQuotes};
  ${pullQuotes};
  ${longQuotes};
  ${videos};
  ${codeBlocks};
  ${tables};
  ${articleContentStyle};
  ${lists};
  ${articleContentAnchorStyle};

  & > .articleSeriesList {
    margin-top: 40px;

  ${({ $headerHeight, $subdomain }) =>
    ($subdomain === Microsites.Recommends && $headerHeight ?
      css`
        scroll-margin-top: ${$headerHeight + 24}px;
      ` :
      css`
        scroll-margin-top: 150px;

        @media (min-width: 353px) {
          scroll-margin-top: 140px;
        }

        @media (min-width: 672px) {
          scroll-margin-top: 130px
        }
      `)};
    
    h4, h5 {
      // overrides styling from headers.tsx
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }

  .tp-container-inner iframe {
    margin-left: 0;
    max-width: 100%;
  }

  @media (${breakpoint.xlMin}) {
    padding-right: 72px;
    padding-left: 48px;
  }
`;

export const TopArticlesContainer = styled.div<{ $isEducation: boolean }>`
  @media (${breakpoint.xlMin}) {
    padding-right: 72px;
    padding-left: 48px;
  }
  ${(props) =>
    props.$isEducation &&
    css`
      margin-bottom: 32px;
      @media (${breakpoint.xlMin}) {
        margin-bottom: 0px;
    }`}
`;

export const ArticleSidebarContainer = styled.div`

  & [data-cy="article-right-rail"] {
    height: 100%; 
  }

  @media (${breakpoint.xlMin}) {
    grid-column: 3 / span 1;
  }

  .most-popular-rr { 
    margin-bottom: 48px;
    margin-top: 24px;

    @media (${breakpoint.xlMin}) {
      margin-top: 0;
    }
  }

  & > div:nth-child(2) {
    flex: 1;
  }
`;

export const FooterStyles = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const AuthorsBioCards = styled.div<{ theme: Theme }>`
  border-top: 3px solid ${getColor('borderAccentPrimary')};
  margin: 48px 0;
 
  h4 {
    margin-top: 8px;
  }

  .author-bio {
    ul {
      list-style-type: none;
      padding: 0;
    }
  }
`;

export const HeaderAdsWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &:empty {
    display: none;
  }
`;
